@keyframes slideIn {
  0% {
    transform: translateX(100rem);
    opacity: 0%; }
  80% {
    opacity: 100%; }
  100% {
    transform: translateX(0); } }

@keyframes slideOut {
  0% {
    transform: translateX(0);
    opacity: 100%; }
  90% {
    transform: translateX(100rem); }
  100% {
    opacity: 0; } }

@keyframes slideUp {
  0% {
    transform: translateY(1rem);
    opacity: 0; }
  60% {
    transform: translateY(-1rem); }
  70% {
    opacity: 1; }
  100% {
    transform: translateY(0rem);
    opacity: 1; } }

.pre__anim {
  opacity: 0;
  visibility: 0; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 50%;
  scroll-behavior: smooth; }
  @media only screen and (min-width: 37.5em) {
    html {
      font-size: 50%; } }
  @media only screen and (min-width: 48em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (min-width: 64em) {
    html {
      font-size: 62.5%; } }

body {
  background-color: #202129;
  color: #BDBDBD;
  box-sizing: border-box;
  font-size: 2rem; }
  @media only screen and (min-width: 64em) {
    body {
      font-size: 2.4rem; } }

h1 {
  margin: 0; }

.app {
  position: relative;
  min-height: 100vh; }

main {
  padding-bottom: 20rem;
  padding: 16rem 0; }
  @media only screen and (min-width: 64em) {
    main {
      padding: 8rem 0; } }

@font-face {
  font-family: 'Helvetica, Inter, "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif';
  font-style: normal;
  font-weight: 800;
  src: url("fonts/Helvetica/Helvetica-CE-Bold/Helvetica-CE-Bold.otf");
  font-display: swap; }

.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 6rem; }
  @media only screen and (min-width: 48em) {
    .heading {
      margin-bottom: 10rem;
      flex-direction: row; } }
  .heading__border {
    height: 2px;
    background-color: #E2E603;
    width: 100%; }
    @media only screen and (min-width: 48em) {
      .heading__border {
        flex: 1; } }
  .heading__primary {
    font-size: 4rem;
    font-family: "Helvetica";
    text-align: center;
    color: #eeeeee;
    position: relative;
    padding: 2rem 0; }
    @media only screen and (min-width: 48em) {
      .heading__primary {
        padding: 0 4rem;
        font-size: 6.4rem; } }
    @media only screen and (min-width: 64em) {
      .heading__primary {
        padding: 0 6rem; } }

.btn,
.btn:link,
.btn:visited {
  text-decoration: none;
  position: relative;
  font-family: "Helvetica";
  border: 2px solid #E2E603;
  color: #E2E603;
  border-radius: 0.4rem;
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.8rem 0;
  cursor: pointer;
  transition: all .2s ease-in; }
  .btn:hover,
  .btn:link:hover,
  .btn:visited:hover {
    transform: translateY(-0.5rem);
    outline: none;
    box-shadow: 3px 4px 0 0 #E2E603;
    transform: translate(-5px, -5px); }
  .btn:active,
  .btn:link:active,
  .btn:visited:active {
    transform: translateY(0); }
  .btn::before,
  .btn:link::before,
  .btn:visited::before {
    pointer-events: none;
    position: absolute;
    background: #E2E603;
    top: 120%;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 60%;
    transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
    filter: blur(1em);
    opacity: 0.7; }
  .btn::after,
  .btn:link::after,
  .btn:visited::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 2em 0.5em #E2E603;
    opacity: 0;
    background-color: #E2E603;
    z-index: -1;
    transition: opacity 100ms linear; }

.project__img {
  max-width: 100%;
  border-radius: 5px;
  transition: .3s ease-in;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); }
  .project__img--container {
    max-width: 100%;
    flex: 0 0 50%;
    position: relative;
    margin-bottom: 4rem; }
    @media only screen and (min-width: 64em) {
      .project__img--container {
        margin-bottom: 0; } }

.project__name {
  font-size: 4rem;
  margin: auto;
  color: #E2E603;
  text-decoration: none;
  font-weight: 700;
  text-align: left;
  display: block;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 48em) {
    .project__name {
      font-size: 4.8rem; } }

.project__content {
  position: relative;
  font-size: 1.8rem; }

.project__desc {
  margin-bottom: 2rem; }

.project__list > * {
  margin-bottom: 1rem; }

.tech__list {
  list-style: none;
  display: flex;
  padding: 0;
  font-size: 1.4rem;
  color: #eeeeee;
  flex-wrap: wrap; }

.tech__item {
  padding: 0.5rem 0; }
  .tech__item:not(:last-child) {
    margin-right: 1rem; }

.tech__p {
  padding: 0.5rem 1.5rem;
  border: 2px solid #E2E603;
  border-radius: 5rem; }
  .tech__p:not(:last-child) {
    margin-right: 1rem; }

.composition {
  position: relative;
  flex: 0 0 50%;
  height: 48.9rem;
  display: flex;
  width: 100%;
  margin-bottom: 4rem; }
  @media only screen and (min-width: 64em) {
    .composition {
      margin-bottom: 0; } }
  .composition__photo {
    width: 100%;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    position: relative;
    z-index: 10;
    transition: all .2s;
    outline-offset: 2rem; }
    @media only screen and (min-width: 64em) {
      .composition__photo {
        position: absolute;
        width: 55%; } }
    @media only screen and (min-width: 64em) {
      .composition__photo--p1 {
        left: 0;
        top: -2rem; } }
    .composition__photo--p2 {
      left: -2rem; }
      @media only screen and (min-width: 64em) {
        .composition__photo--p2 {
          left: auto;
          right: 0;
          top: 2rem;
          z-index: 10; } }
    .composition__photo--p3 {
      left: -2rem; }
      @media only screen and (min-width: 64em) {
        .composition__photo--p3 {
          left: 20%;
          top: 10rem; } }
    .composition__photo:hover {
      transform: scale(1.05) translateY(-0.5rem);
      box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
      filter: grayscale(0);
      z-index: 20; }
  .composition:hover .composition__photo:not(:hover) {
    transform: scale(0.95); }

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 0; }
  @media only screen and (min-width: 48em) {
    .footer {
      padding: 4rem; } }
  .footer__text {
    font-size: 1.4rem;
    margin-bottom: 2rem; }
  .footer__socials {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
  .footer__icon {
    color: #BDBDBD;
    width: 2.4rem;
    height: 2.4rem;
    padding: 1.4rem;
    box-sizing: content-box; }
  .footer__caption {
    font-size: 1.2rem; }

.container {
  flex: 1;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 54.0rem !important; }
  @media only screen and (min-width: 48em) {
    .container {
      max-width: 76.8rem !important; } }
  @media only screen and (min-width: 64em) {
    .container {
      max-width: 138rem !important; } }
  .container-fluid {
    width: 100%;
    padding: 0;
    margin: 0 auto; }

.row {
  width: 100%;
  margin: 0 auto; }
  .row::after {
    content: "";
    display: table;
    clear: both; }
  .row .col-12 {
    float: left;
    padding: 0 3rem;
    width: 100%; }
    @media only screen and (min-width: 48em) {
      .row .col-12 {
        padding: 0 9rem; } }

.flex-reverse {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  @media only screen and (min-width: 64em) {
    .flex-reverse {
      flex-direction: row; }
      .flex-reverse:nth-child(odd) {
        flex-direction: row-reverse; }
        .flex-reverse:nth-child(odd) > *:first-child {
          margin-left: 6rem; }
      .flex-reverse:nth-child(even) > *:first-child {
        margin-right: 6rem; } }
  .flex-reverse:not(:last-child) {
    margin-bottom: 16rem; }

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  width: 100%;
  font-family: "Helvetica";
  transition: all .1s ease-in, transform .3s ease-in;
  position: absolute;
  z-index: 100; }
  .nav__logo, .nav__logo:link, .nav__logo:visited {
    text-decoration: none;
    font-size: 2rem;
    font-family: helvetica;
    cursor: pointer;
    transform: translateY(0);
    transition: all .1s ease-in;
    color: #BDBDBD;
    font-weight: 700; }
    .nav__logo:hover, .nav__logo:link:hover, .nav__logo:visited:hover {
      transform: translateY(-0.35rem); }
    .nav__logo:active, .nav__logo:link:active, .nav__logo:visited:active {
      transform: translateY(0); }
  .nav__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    overflow-x: scroll; }
    @media only screen and (min-width: 48em) {
      .nav__list {
        flex-direction: row;
        margin-right: 4rem;
        overflow-x: hidden; } }
  .nav__item {
    margin-bottom: 6rem; }
    @media only screen and (min-width: 48em) {
      .nav__item {
        margin-bottom: 0; }
        .nav__item:not(:last-child) {
          margin-right: 4rem; } }
  .nav__link {
    color: #eeeeee;
    text-decoration: none;
    font-size: 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media only screen and (min-width: 48em) {
      .nav__link {
        flex-direction: row;
        font-size: 1.4rem; } }
  .nav__text {
    display: block; }
    .nav__text::after {
      display: block;
      content: "";
      border-bottom: 1px solid #E2E603;
      transform: scaleX(0);
      transition: transform .15s ease-in;
      transform-origin: 0 50%; }
    .nav__text:hover::after {
      transform: scaleX(1); }
  .nav__index {
    color: #E2E603;
    margin-bottom: 1rem; }
    @media only screen and (min-width: 48em) {
      .nav__index {
        margin-right: 0.5rem;
        margin-bottom: 0; } }
  .nav__cta {
    width: 12rem; }
  @media only screen and (min-width: 48em) {
    .nav .nav__btn {
      padding: 1rem 0;
      font-size: 1.4rem; } }
  .nav__content {
    flex: 1;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 65%;
    position: fixed;
    background-color: #16171c;
    right: 0;
    top: 0;
    z-index: 10;
    overflow-x: visible;
    padding: 2rem 0; }
    @media only screen and (min-width: 48em) {
      .nav__content {
        padding: 0;
        position: relative;
        background-color: transparent;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end; } }
  .nav__icon {
    cursor: pointer;
    display: block;
    height: 6.4rem;
    width: 6.4rem;
    z-index: 200;
    position: relative; }
    @media only screen and (min-width: 48em) {
      .nav__icon {
        display: none; } }
  .nav__menu, .nav__menu::before, .nav__menu::after {
    background: #E2E603;
    content: '';
    display: block;
    height: 0.2rem;
    position: absolute;
    transition: background ease .3s, top ease .3s .3s, transform ease .3s;
    width: 4.8rem;
    border-radius: 5rem; }
  .nav__menu {
    left: 1.8rem;
    top: 2.7rem; }
    .nav__menu::before {
      top: -1.2rem;
      width: 3.2rem; }
    .nav__menu::after {
      width: 3.2rem;
      top: 1.2rem;
      left: 1.6rem; }
  .nav__active {
    display: flex;
    animation: slideIn .6s linear; }
  .nav__inactive {
    display: flex;
    animation: slideOut .6s linear;
    transform: translateX(100rem); }

#nav__menu {
  display: none; }
  #nav__menu:checked + .nav__icon .nav__menu {
    transform: rotate(225deg);
    transition: transform ease .3s .3s; }
    #nav__menu:checked + .nav__icon .nav__menu::before, #nav__menu:checked + .nav__icon .nav__menu::after {
      top: 0;
      transition: top ease .3s, all ease .3s .3s; }
    #nav__menu:checked + .nav__icon .nav__menu::before {
      width: 4.8rem; }
    #nav__menu:checked + .nav__icon .nav__menu::after {
      width: 4.8rem;
      left: 0;
      transform: rotate(90deg); }

.sticky {
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: rgba(32, 33, 41, 0.7);
  backdrop-filter: blur(10px);
  padding: 0.5rem 3rem;
  box-shadow: 0 10px 30px -10px rgba(22, 23, 28, 0.7); }
  @media only screen and (min-width: 48em) {
    .sticky {
      padding: 1.5rem 3rem; } }

.socialside {
  display: none;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  padding: 0 3rem;
  z-index: 100; }
  @media only screen and (min-width: 48em) {
    .socialside {
      display: flex; } }
  .socialside::after {
    content: "";
    width: 2px;
    height: 120px;
    margin: 0 auto;
    background-color: #BDBDBD; }
  .socialside__icon {
    width: 2.5rem;
    height: 2.5rem;
    fill: #BDBDBD;
    cursor: pointer;
    margin-bottom: 3rem;
    transform: translateY(0);
    transition: all .3s ease-in; }
    .socialside__icon:hover {
      transform: translateY(-0.5rem); }

#homepage {
  position: relative;
  width: 100%; }

.hero {
  width: 100%;
  overflow: hidden; }
  .hero__container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    justify-content: space-between;
    flex-direction: column; }
    @media only screen and (min-width: 64em) {
      .hero__container {
        flex-direction: row;
        height: 100vh; } }
  .hero__left {
    width: 100%; }
    @media only screen and (min-width: 64em) {
      .hero__left {
        max-width: 65.8rem;
        margin-right: 2rem;
        flex: 0 0 50%; } }
  .hero__title {
    color: #E2E603;
    font-size: 4rem;
    font-family: "Helvetica";
    position: relative;
    text-align: center; }
    @media only screen and (min-width: 64em) {
      .hero__title {
        text-align: left; } }
    .hero__title--1 {
      font-size: 4.8rem;
      margin-bottom: 1.8rem;
      color: transparent; }
      @media only screen and (min-width: 48em) {
        .hero__title--1 {
          margin-bottom: 0; } }
    @media only screen and (min-width: 48em) {
      .hero__title {
        font-size: 6.4rem; } }
    .hero__title:last-child {
      margin-bottom: 2.4rem; }
    .hero__title--striped:before {
      content: attr(data-text);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-shadow: 1px 1px 0px #fff, 1.3px 1.3px 0px #fff, 2px 2px 0px #E2E603, 3px 3px 0px #E2E603, 4px 4px 0px #E2E603, 5px 5px 0px #E2E603, 6px 6px 0px #E2E603, 7px 7px 0px #E2E603;
      color: transparent;
      width: 100%; }
      @media only screen and (min-width: 48em) {
        .hero__title--striped:before {
          left: 0;
          top: 0;
          transform: translate(0, 0); } }
    .hero__title--striped:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: -200vmax;
      left: 0;
      right: 0;
      bottom: -200vmax;
      background-image: repeating-linear-gradient(0deg, transparent 0 1px, #fff 1.5px 3px);
      transform: skewY(45deg); }
  .hero__intro {
    color: #E2E603;
    font-size: 2.4rem;
    font-family: "Helvetica";
    position: relative;
    text-align: center;
    margin-bottom: 2rem;
    animation: slideUp .6s ease-in; }
    @media only screen and (min-width: 64em) {
      .hero__intro {
        text-align: left; } }
    @media only screen and (min-width: 48em) {
      .hero__intro {
        font-size: 3.4rem; } }
  .hero__name {
    color: #eeeeee;
    font-family: "Helvetica";
    font-size: 3.2rem;
    position: relative;
    text-align: center;
    margin-bottom: 1rem;
    animation: slideUp .6s ease-in .1s;
    overflow: hidden; }
    @media only screen and (min-width: 64em) {
      .hero__name {
        text-align: left; } }
    @media only screen and (min-width: 48em) {
      .hero__name {
        font-size: 6.4rem; } }
  .hero__subtitle {
    font-size: 1.8rem;
    margin-bottom: 4rem;
    text-align: center;
    animation: slideUp .6s ease-in .2s; }
    @media only screen and (min-width: 48em) {
      .hero__subtitle {
        font-size: 2.4rem; } }
    @media only screen and (min-width: 64em) {
      .hero__subtitle {
        text-align: left; } }
  .hero__img {
    max-width: 57.6rem;
    width: 100%; }
  .hero__right {
    flex: 0 0 50%; }
  .hero__btn {
    max-width: 28rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10rem; }
    @media only screen and (min-width: 64em) {
      .hero__btn {
        margin: 0;
        margin-bottom: 0; } }

.contact__photo {
  filter: grayscale(1);
  max-width: 24rem;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 4rem;
  transition: all .3s ease-in; }
  .contact__photo:hover {
    filter: grayscale(0);
    transform: scale(1.1); }

.contact__text {
  font-size: 1.8rem;
  text-align: center;
  max-width: 100%;
  margin: 0 auto; }
  @media only screen and (min-width: 48em) {
    .contact__text {
      max-width: 50%; } }

#projects {
  padding: 6rem 0; }
  @media only screen and (min-width: 64em) {
    #projects {
      padding: 10rem 0; } }

#skills {
  padding: 6rem 0; }
  @media only screen and (min-width: 64em) {
    #skills {
      padding: 10rem 0; } }

#contact {
  padding: 6rem 0; }
  @media only screen and (min-width: 64em) {
    #contact {
      padding: 10rem 0; } }

.skills {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto; }
  @media only screen and (min-width: 64em) {
    .skills {
      width: 80%; } }
  .skills__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem; }
    @media only screen and (min-width: 64em) {
      .skills__item {
        padding: 4rem; } }
  .skills__icon {
    width: 8rem;
    height: 8rem;
    margin-bottom: 2rem; }
  .skills__text {
    font-size: 1.8rem; }

#singleproject > * {
  margin-bottom: 10rem; }
  @media only screen and (min-width: 64em) {
    #singleproject > * {
      margin-bottom: 20rem; } }

.project__title {
  position: relative;
  font-family: "Helvetica";
  font-size: 4.8rem;
  text-align: center;
  color: #E2E603;
  margin-bottom: 4rem; }
  @media only screen and (min-width: 48em) {
    .project__title {
      margin-bottom: 0;
      margin: 8rem 0;
      font-size: 10rem; } }
  .project__title::before {
    content: '';
    position: absolute;
    top: 4rem;
    transform: scale(1, -1);
    backface-visibility: visible;
    z-index: 1; }
    @media only screen and (min-width: 48em) {
      .project__title::before {
        top: 10rem;
        content: attr(data-text); } }
  .project__title::after {
    content: '';
    position: relative;
    display: block;
    top: -8px;
    left: 0;
    width: 100%;
    height: 6rem;
    background-image: linear-gradient(to bottom, rgba(32, 33, 41, 0.8) 0%, #202129 50%);
    z-index: 2; }
    @media only screen and (min-width: 48em) {
      .project__title::after {
        top: -.8rem;
        height: 10rem; } }

.project__image {
  max-width: 115rem;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 10rem; }

.project__btn {
  max-width: 30rem;
  margin: 0 auto; }

.project__video {
  max-width: 100%;
  margin-top: 6rem;
  border-radius: 2rem; }
